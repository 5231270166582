import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LocalFile, parse } from 'papaparse';
// Contexts/hooks/services
import { Context as AuthContext } from '../context/Auth';
import { useOrganization } from '../services/Organization';
import {
    Context as notificationContext,
    MessageType,
} from '../context/Notification';
import {
    StepTitles,
    useBulkVerifyStepsContext,
} from '../context/BulkVerifyStepsContext';
// Components
import uploadFileImg from '../img/bulkStep1Cover.png';
import { Grid, Typography } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import Checkbox from '@material-ui/core/Checkbox';
import Button from './Button';
import BulkStepLoadingButton from './BulkStepLoadingButton';

const Step1 = () => {
    const history = useHistory();

    const { dispatch: notificationDispatch } = useContext(notificationContext);

    const {
        hideInternationalVerification,
        prevRoute,
        file,
        setFile,
        setFileHeaders,
        setFileRowCount,
        setUseProperCase,
        setUseGeocode,
        setRunNCOA,
        setInternational,
        setActiveStep,
    } = useBulkVerifyStepsContext();
    const org = useOrganization([]);

    const { state: authState } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const onNext = () => {
        setActiveStep(StepTitles.MAP_FIELDS);
    };

    const onPrev = () => {
        history.push(prevRoute);
    };

    const handleNext = async () => {
        if (!file) {
            notificationDispatch({
                type: MessageType.ERROR,
                message: 'Please select a file.',
            });

            return;
        }
        setLoading(true);

        let count = 0;

        let firstRow = true;

        parse(file as LocalFile, {
            step: (row) => {
                // Keep headers to set headers
                if (firstRow) {
                    setFileHeaders(row.data as string[]);
                    firstRow = false;
                    return;
                }

                count++;
            },
            complete: () => {
                setFileRowCount(count);
                setLoading(false);
                onNext();
            },

            // Roughly approximates what we have on the backend sans the empty address line 1 check
            skipEmptyLines: 'greedy',
        });
    };

    const showGeocoding = useMemo(() => {
        // Make sure this is a bool, otherwise it will show up in the dom
        return !!(
            org?.enableBulkGeocoding ||
            (authState.user?.permissions &&
                authState.user?.permissions & (1 << 2))
        );
    }, [org, authState]);

    const showNCOA = useMemo(() => {
        // Make sure this is a bool, otherwise it will show up in the dom
        return !!(
            org?.enableBulkNCOA ||
            (authState.user?.permissions &&
                authState.user?.permissions & (1 << 2))
        );
    }, [org, authState]);

    return (
        <>
            <Grid
                container
                item
                spacing={4}
                alignContent="center"
                alignItems="center"
                justify="center"
            >
                <Grid item xs={8}>
                    <DropzoneArea
                        acceptedFiles={['.csv']}
                        // @ts-ignore
                        dropzoneText={
                            file ? (
                                <>
                                    <img
                                        src={uploadFileImg}
                                        alt="File Upload Img"
                                        draggable={false}
                                        style={{
                                            cursor: 'pointer',
                                            border: '2px rgba(37, 105, 230, 0.5) dashed',
                                            borderRadius: '20px',
                                            padding: '20px',
                                            width: '100%',
                                        }}
                                    />
                                    {file.name}
                                </>
                            ) : (
                                <img
                                    src={uploadFileImg}
                                    alt="File Upload Img"
                                    draggable={false}
                                    style={{
                                        cursor: 'pointer',
                                        border: '2px rgba(155, 155, 155, 0.5) dashed',
                                        borderRadius: '20px',
                                        padding: '20px',
                                        width: '100%',
                                    }}
                                />
                            )
                        }
                        onChange={(files) => {
                            setFile(files[0]);
                        }}
                        maxFileSize={100 * 1000 * 1000} // 100mb
                        filesLimit={1}
                        showPreviewsInDropzone={false}
                        showAlerts={true}
                        alertSnackbarProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }}
                    />
                </Grid>

                <Grid
                    item
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    container
                    spacing={0}
                    xs={12}
                >
                    {showGeocoding && (
                        <Grid
                            item
                            container
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item>
                                <Checkbox
                                    name="Geocode Select"
                                    color="primary"
                                    onChange={() => {
                                        setUseGeocode(
                                            (useGeocode) => !useGeocode
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Typography>
                                    Please check this box to enable geolocation
                                    (Additional charge applies).
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {showNCOA && (
                        <Grid
                            item
                            container
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item>
                                <Checkbox
                                    name="NCOA Select"
                                    color="primary"
                                    onChange={() => {
                                        setRunNCOA((prev: boolean) => !prev);
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Typography>
                                    Please check this box to enable NCOA
                                    processing (Additional charge applies, US
                                    addresses only).
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid
                        item
                        container
                        alignContent="center"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <Checkbox
                                name="ProperCase Select"
                                color="primary"
                                onChange={() => {
                                    setUseProperCase(
                                        (useProperCase) => !useProperCase
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <Typography>
                                Please check this box if you want your addresses
                                to be produced in mixed case (145 Mulberry St)
                                instead of upper case (145 MULBERRY ST).
                            </Typography>
                        </Grid>
                    </Grid>

                    {!hideInternationalVerification && (
                        <Grid
                            item
                            container
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item>
                                <Checkbox
                                    name="International Select"
                                    color="primary"
                                    onChange={() => {
                                        setInternational((prev) => !prev);
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Typography>
                                    Please check the box to verify addresses
                                    outside of US and Canada (International
                                    rates will apply).
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid item container justify="center" spacing={3}>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={onPrev}
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <BulkStepLoadingButton
                            disabled={!file}
                            loading={loading}
                            onClick={handleNext}
                        >
                            Next
                        </BulkStepLoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Step1;
